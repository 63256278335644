// src/DrawingBoard.js
import React, { useRef, useState } from 'react';
import CanvasDraw from "react-canvas-draw";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import './DrawingBoard.css';

const DrawingBoard = () => {
  const [color, setColor] = useState('black');
  const [brushRadius, setBrushRadius] = useState(1);
  const [tool, setTool] = useState('pen');
  const canvasRef = useRef(null);

  const printDrawing = () => {
    const canvas = canvasRef.current.canvasContainer.children[1];
    const dataUrl = canvas.toDataURL('image/png');
    const windowContent = `
      <!DOCTYPE html>
      <html>
      <head><title>Print canvas</title></head>
      <body>
      <img src="${dataUrl}" onload="window.print();window.close()" />
      </body>
      </html>
    `;
    const printWin = window.open('', '', 'width=800,height=600');
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.close();
  };

  const clearBoard = () => {
    canvasRef.current.clear();
  };

  const setPenTool = () => {
    setTool('pen');
    setColor('black');
  };

  const setEraserTool = () => {
    setTool('eraser');
    setColor('white');
  };

  return (
    <div className="drawing-board">
      <CanvasDraw
        ref={canvasRef}
        brushColor={color}
        brushRadius={brushRadius}
        lazyRadius={0}
        canvasWidth={800}
        canvasHeight={600}
      />
      <div className="controls">
        <div className="tool-palette">
          <button onClick={setPenTool} className={tool === 'pen' ? 'active' : ''}>
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button onClick={setEraserTool} className={tool === 'eraser' ? 'active' : ''}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
          <div className="color-palette">
            <button onClick={() => setColor('black')} style={{ backgroundColor: 'black' }}></button>
            <button onClick={() => setColor('red')} style={{ backgroundColor: 'red' }}></button>
            <button onClick={() => setColor('green')} style={{ backgroundColor: 'green' }}></button>
            <button onClick={() => setColor('blue')} style={{ backgroundColor: 'blue' }}></button>
          </div>
          <div className="thickness-slider">
            <label htmlFor="thickness">Thickness:</label>
            <input
              id="thickness"
              type="range"
              min="1"
              max="20"
              value={brushRadius}
              onChange={(e) => setBrushRadius(e.target.value)}
            />
          </div>
        </div>
        <div className="action-buttons">
          <button onClick={clearBoard} className="action-button">
            <FontAwesomeIcon icon={faTrash} /> Clear Board
          </button>
          <button onClick={printDrawing} className="action-button">
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrawingBoard;
